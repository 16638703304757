import React ,{ useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import logo from './logo.svg';
import './App.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { setAuthInfo, setCustomPageExhibitorlist, setBookmarkList} from './actions/main.js'
import { setOpenCompanyProfile, setLeftBoothList, setBookmardUpdated, setOpenLogin, setLeftSelectedCategory, setLeftPage, setOpenCompanyModal, setEditCompany, setSnackMsg, setOpenSnack } from './actions/ui.js'
import { parseToken, getTokenCookie, setCookie,assetUrl, getTokenPlanner, composeUrl,convertRgb } from './utils/Common.js'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
import qs, { parse } from 'qs'
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import LeftProfile from  './viewer/component/LeftProfile.js';
import { useParams, useNavigate } from 'react-router-dom'
import { API_URL, ESHOW_URL } from './config.js'
import axiosInstance from './utils/axiosInstance.js'
import IconButton from '@mui/material/IconButton';

import BookmarkBorderSharpIcon from '@mui/icons-material/BookmarkBorderSharp';
import BookmarkSharpIcon from '@mui/icons-material/BookmarkSharp';
import LoginModal from './viewer/component/LoginModal'
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import ToolBox from './viewer/ToolBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function descendingComparator(a, b, orderBy) {
  if(orderBy == 'size') orderBy = 'sqft'
  var a_val = (a[orderBy])?a[orderBy]:''
  var b_val = (b[orderBy])?b[orderBy]:''
  return a_val.toString().localeCompare(b_val.toString(), 'en', { numeric: true })
  // a_val = a[orderBy]
  // b_val = b[orderBy]
  
  // if(a_val) a_val = a_val.trim()
  // if(b_val) b_val = b_val.trim()

  // var returnVal = 0
  // if (b_val < a_val) {
  //   returnVal = -1;
  // }
  // if (b_val > a_val) {
  //   returnVal = 1;
  // }
  
  // a_val = !isNaN(a_val)?a_val*1:a_val*1
  // b_val = !isNaN(b_val)?b_val*1:b_val*1
  // if (b_val < a_val) {
  //   returnVal = -1;
  // }
  // if (b_val > a_val) {
  //   returnVal = 1;
  // }
  // return returnVal;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    // if (order !== 0) {
      return order;
    // }
    // return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const responsive1 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2.5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2.5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2.5
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function paginate(array, page_size, page_number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

function Index(props) {
  
  const theme = useTheme();
  const { id1, id2, id3, id4, id5 } = useParams();
  let query = useQuery();
  const [data, setData] = useState([]);
  // const [exhibitorlist, setExhibitorlist] = useState([]);
  const [loaded, setLoaded] = useState(false)
  const [page, setPage] = React.useState(1);
  const [pulldownValue, setPulldownValue] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);
  const [keySelect, setKeySelect] = useState('')
  const [filteredItems, setFilteredItems] = useState([])
  const [pageSetup, setPageSetup] = useState([])
  
  const navigate = useNavigate()
  const page_size = 24;
  const keywordInput = useRef(null);
  const selectRef = useRef(null);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('COMPANY_NAME');

  let exhibitorlist = props.main.customPageExhibitorlist
  
  const useStyles = makeStyles((theme) => ({
    searchBtn:{
      backgroundColor: props.ui?.customStyles?.['search-button-bg-color']?(props.ui?.customStyles['search-button-bg-color']+"!important"):'#1565c0',
      color: props.ui?.customStyles?.['search-button-label-color']?(props.ui?.customStyles['search-button-label-color']+"!important"):'#1565c0',
    },
    bubble:{
      color:props.ui?.customStyles?.['bubble-label-color']?(props.ui?.customStyles['bubble-label-color']+"!important"):'white',
      backgroundColor:props.ui?.customStyles?.['bubble-bg-color']?(props.ui?.customStyles['bubble-bg-color']+"!important"):'#1565c0',
    }
  }));

  const classes = useStyles();
  

  const fetchCustomStyles = async ()=>{
    if(query.get('sales_map')) {                        
      props.setCustomStyles({})
      return;
    }
    console.log("fetchCustomStyles")
    var url  = `${ESHOW_URL()}?method=getCSS`
    const options = {
      headers: {
        'Authorization': `Bearer ${getTokenCookie()}`
      }
    } 
    const response = await axios.get(url, options)
    if(response.status == 200){
        var data = response.data
        if(data.SUCCESS == true){
            if(data.CSS){
                console.log("result.CSS", data.CSS)                    
                props.setCustomStyles(data.CSS)
            }
        }
    }
    else{
        console.log("")
    }
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fafafa',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display:'block'
  }));



  useEffect(() => {
    // if(props.urlToken){
    //     var public_token = `/${id1}/${id2}/${id3}`
    //     setCookie('V3PUBLICMAP', public_token+(id4?`/${id4}`:''))
    // }
    // processAuth()
    initForm()
  }, [id5, id4, id3, id2, id1])

  useEffect(() => {
    if(exhibitorlist.length)
      doSearch()
  }, [keySelect, pulldownValue])


  const initForm = async()=>{
    if(props.urlToken){
      var public_token = `/${id1}`
        if(id2){
          public_token = `${public_token}/${id2}`
        }
        if(id3){
          public_token = `${public_token}/${id3}`
        }
        if(id4){
          public_token = `${public_token}/${id4}`
        }
        if(id5){
          public_token = `${public_token}/${id5}`
        }
        setCookie('V3PUBLICMAP', public_token)
    }

    if(1){
      {
        if(!exhibitorlist?.length){
          setLoaded(false)
          var result = await getExhibitorList()
          props.setCustomPageExhibitorlist(result)
          setFilteredItems(result)
        }
        setLoaded(true)        
      }
    }
  }
  
  const getExhibitorList = async ()=>{
    var result = null
    var token = false
    try{
      token =  getTokenPlanner();
      if(props.role=='admin'){
        token = getTokenCookie()
      }
      else{
        if(!token){
          token = getTokenCookie()
        }
      }
    }
    catch(e){

    }
    
    var booth = props.ui.openCompanyProfile.booth;
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }  
    var url = `${ESHOW_URL()}?method=getExhibitorList`
   
    const response = await axios.get(url, options);
    if(response.status == 200){
      if(response.data?.SUCCESS == true){
        result = response.data?.EXHIBITORS;
        setSearchOptions(response.data?.SEARCH?.STANDARD)
        
        if(response.data?.SEARCH?.STANDARD && response.data?.SEARCH?.STANDARD?.length){
          setKeySelect(response.data?.SEARCH?.STANDARD[0].LABEL)
        }
        if(response.data?.SETUP){
          setPageSetup(response.data?.SETUP)
        }
      }
      else{
        props.setOpenSnack(true);
        props.setSnackMsg(response.data?.MESSAGE?response.data?.MESSAGE:"Something went wrong");
      }
        
    }
    else{
      props.setOpenSnack(true);
      props.setSnackMsg("Something went wrong");
    }
    return result

 }
 
 const callBookmarkApi= async (exhibitor, value)=>{
  const _token = getTokenPlanner();
  var BOOKMARKS = props.main.bookmarkList?props.main.bookmarkList:[]
  if(value){
    BOOKMARKS = [
      ...BOOKMARKS,
      {
        COMPANY_NAME: exhibitor?.company?exhibitor?.company:(exhibitor?.COMPANY_NAME?exhibitor?.COMPANY_NAME:""),
        NOTES:"",
        STATUS:'',
        BOOTHS:exhibitor.BOOTHS,
        EXHIBITOR_KEY:exhibitor?.EXHIBITOR_KEY?exhibitor?.EXHIBITOR_KEY:"" 
      }
    ]
  }
  else{
    BOOKMARKS = BOOKMARKS.filter((item, index) => `${item.EXHIBITOR_KEY}` !== `${exhibitor.EXHIBITOR_KEY}`)
  }
  
  var postData =
  {
    SUCCESS:true,
    BOOKMARKS:BOOKMARKS
  };

  var formdata = {
    query:`${ESHOW_URL()}?method=putPlanner&token=${_token}`,
    postData: postData,
    returnResponse: true
  }
  var data = qs.stringify({
      request: JSON.stringify(formdata)
  });
  const options = {
    headers: {
      'body': '[{"company:","test"}]', 
      'Content-Type': 'application/json'
    }
  }
  var url = `${ESHOW_URL()}?method=putPlanner&token=${_token}`
  axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
    const eshowResponse = res.data;
    if(eshowResponse.SUCCESS == true){
    }
    else{
      props.setOpenSnack(true);
      props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to save bookmark");
    }
    props.setBookmarkList(BOOKMARKS)
  }).catch(error => {
      console.log("error", error);
  })
}

 const doBookmark = (exhibitor)=>{
  if(exhibitor){
    const _token = getTokenPlanner();
    if((props.role !='admin') && (!_token || !_token)){
      props.setOpenLogin(true)
      return false;
    }
    var newBoothList = [...exhibitorlist];
    for(var i =  0; i< newBoothList.length; i++){
      if(`${newBoothList[i]['EXHIBITOR_KEY']}` == `${exhibitor.EXHIBITOR_KEY}`){
        callBookmarkApi(exhibitor, !newBoothList[i]['BOOKMARKED'])
        newBoothList[i]['BOOKMARKED'] = !newBoothList[i]['BOOKMARKED']
      }
    }
    
    props.setCustomPageExhibitorlist(newBoothList)
    // props.setBookmardUpdated(true)
  }
}

const resetSearch=()=>{
  if(keywordInput?.current)
    keywordInput.current.value = ''
  if(selectRef?.current)
    selectRef.current.value = ''
  setFilteredItems(exhibitorlist)
}

const doSearch=(value)=>{
  var keyword = null
  let searchOption = getSearchOption(keySelect)
  if(value){
    keyword = value
  }
  else{
    if(searchOption && searchOption.TYPE == 'pulldown'){
      keyword = pulldownValue
    }
    else{
      keyword = keywordInput?.current?.value
    }
  }
  if(!keyword) keyword = ''
  keyword = `${keyword}`
  var filteredItems = exhibitorlist;
  if(keyword == "" || keyword == '""' || !keyword){
    setFilteredItems(filteredItems)
    return;
  }
  if(keyword && keyword.trim() !== '') {
    filteredItems = exhibitorlist.filter((item)=>{
      var str = ''
      if(keySelect == "Company Description"){
        str = `${item.DESCRIPTION?item.DESCRIPTION:''}`
        return (str.toLowerCase().includes(keyword.toLowerCase()))
      }
      else if(keySelect == "Exhibitor Name"){
        str = `${item.COMPANY_NAME?item.COMPANY_NAME:''}`
        return (str.toLowerCase().includes(keyword.toLowerCase()))
      }
      else if(keySelect == "Product Categories"){
        var categories = item.CATEGORY;
        var keys = [];
        for(var i = 0; i< categories?.length; i++){
          keys.push(categories[i].ANSWER_KEY)
        }
        return (keys?.includes(keyword))
      }          
      else if(keySelect == "Booth Number"){
        str = `${item.BOOTHS?item.BOOTHS:''}`
        return (str.toLowerCase().includes(keyword.toLowerCase()))
      }
      else if(keySelect == "Map Name"){
        str = `${item.MAP_NAME?item.MAP_NAME:''}`
        return (str.toLowerCase().includes(keyword.toLowerCase()))
      }
      else if(keySelect == "States"){
        str = `${item.STATE?item.STATE:''}`
        return (str.toLowerCase()==(keyword.toLowerCase()))
      }
      else if(keySelect == "Countries"){
        str = `${item.COUNTRY?item.COUNTRY:'us'}`
        return (str.toLowerCase()==(keyword.toLowerCase()))
      }
      else if(keySelect == 'Featured Exhibitors'){
        str = `${item.FEATURED?item.FEATURED:''}`
        return (str.toLowerCase().includes(keyword.toLowerCase()))
      }
      else if(keySelect == "Region of Services"){
        var categories = item.CATEGORY;
        var keys = [];
        for(var i = 0; i< categories?.length; i++){
          keys.push(categories[i].ANSWER_KEY)
        }
        return (keys?.includes(keyword))
      }     
      else if(keySelect == "Service Specialty"){
        var categories = item.CATEGORY;
        var keys = [];
        for(var i = 0; i< categories?.length; i++){
          keys.push(categories[i].ANSWER_KEY)
        }
        return (keys?.includes(keyword))
      }             
      else{
        var categories = item.CATEGORY;
        var keys = [];
        for(var i = 0; i< categories?.length; i++){
          keys.push(categories[i].ANSWER_KEY)
        }
        return (keys?.includes(keyword))
      }
    })      
  }
  setFilteredItems(filteredItems)
}


const createSortHandler = (property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
};

const getSearchOption = (value)=>{
  console.log("searchOptions", searchOptions)
  if (!value) return false;
  for(var i = 0; i < searchOptions?.length; i++){
    if(searchOptions[i].LABEL == value){
      return searchOptions[i]
    }
  }
  return false
}

const searchBox = ()=>{
  let searchOption = getSearchOption(keySelect)
  let dropdownOptions = [];
  if(searchOption && searchOption.TYPE == 'pulldown'){
    if(searchOption.ID == 'question'){
      for(var i = 0; i < searchOption.ANSWERS?.length; i++){
        let title = searchOption.ANSWERS[i].answer_title
        if(title === true){
          title = "Yes"
        }
        if(title === false){
          title = "No"
        }
        dropdownOptions.push(
          {
            value:`${searchOption.ANSWERS[i].answer_key}`?.trim(),
            label:`${title}`?.trim()+(searchOption.ANSWERS[i].qty?(` (${searchOption.ANSWERS[i].qty})`):""),
          }
        )
      }
    }
    else if(searchOption.ID == 'map_name'){
      for(var i = 0; i < searchOption.ITEMS?.length; i++){
        dropdownOptions.push(
          {
            value:`${searchOption.ITEMS[i].TITLE??searchOption.ITEMS[i]['"TITLE"']}`?.trim(),
            label:`${searchOption.ITEMS[i].TITLE??searchOption.ITEMS[i]['"TITLE"']}`?.trim(),
          }
        )
      }
    }
    else if(searchOption.ID == 'state'){
      for(var i = 0; i < searchOption.ITEMS?.length; i++){
        dropdownOptions.push(
          {
            value:`${searchOption.ITEMS[i].ID??searchOption.ITEMS[i]['ID']}`?.trim(),
            label:`${searchOption.ITEMS[i].TITLE??searchOption.ITEMS[i]['"TITLE"']}`?.trim()+(searchOption.ITEMS[i].QTY?(` (${searchOption.ITEMS[i].QTY})`):""),
          }
        )
      }
    }
    else if(searchOption.ID == 'country'){
      for(var i = 0; i < searchOption.ITEMS?.length; i++){
        dropdownOptions.push(
          {
            value:`${searchOption.ITEMS[i].ID??searchOption.ITEMS[i]['ID']}`?.trim(),
            label:`${searchOption.ITEMS[i].TITLE??searchOption.ITEMS[i]['"TITLE"']}`?.trim()+(searchOption.ITEMS[i].QTY?(` (${searchOption.ITEMS[i].QTY})`):""),
          }
        )
      }
    }
    else{
      for(var i = 0; i < searchOption.ITEMS?.length; i++){
        dropdownOptions.push(
          {
            value:`${searchOption.ITEMS[i].ID??searchOption.ITEMS[i]['ID']}`?.trim(),
            label:`${searchOption.ITEMS[i].TITLE??searchOption.ITEMS[i]['"TITLE"']}`?.trim()+(searchOption.ITEMS[i].QTY?(` (${searchOption.ITEMS[i].QTY})`):""),
          }
        )
      }
    }
  }
  return (
    <Paper
      className='search-bar'
      component="form"
      sx={{ p: '0px 0px', display: 'flex', alignItems: 'center', maxWidth: '750px' }}
    >
      <FormControl variant="outlined" style={{width:'170px', border:'none'}}>
        <Select
          className='key-select'
          style={{border:'none', outline:'none'}}
          // displayEmpty
          value={keySelect?keySelect:'Keyword'}
          onChange={(e)=>{
            setKeySelect(e.target.value); setPulldownValue(false);
            if(keywordInput?.current)
              keywordInput.current.value = ''
            if(selectRef?.current)
              selectRef.current.value = ''
          }}
        >
          {
            searchOptions.map((searchOption, index)=>{
              return(
              <MenuItem value={searchOption.LABEL} key={index}>{searchOption.LABEL}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
      
      <Divider sx={{ height: '32px', m: 0.5 }} orientation="vertical" />
      
      {searchOption.TYPE == 'pulldown' ?
        <Select
          className='key-select'
          sx={{ flex: 1, width:300,border:'none', outline:'none', textAlign:'left'  }}
          // displayEmpty
          ref={selectRef}
          value = {pulldownValue}
          onChange={(e)=>{setPulldownValue(e.target.value)}}
        >
          {
            dropdownOptions.map((dropdownOption, index)=>{
              return(
                <MenuItem value={dropdownOption.value} key={index}>{dropdownOption.label}</MenuItem>
              )
            })
          }
        </Select>:
        <InputBase
          sx={{ flex: 1, width:300  }}
          placeholder="Search by keyword"
          inputProps={{ 'aria-label': 'Search by keyword' }}
          onKeyDown={
            (e)=>{
                if(e.keyCode == 13){
                    e.preventDefault();
                    doSearch()
                }
            }
          }
          inputRef={keywordInput}
        />
      }
      {searchOption.TYPE != 'pulldown' &&
      <IconButton aria-label="delete" onClick={resetSearch} style={{padding:0, marginRight:5}}>
        <HighlightOffIcon  sx={{ p: '5px' }} />
      </IconButton>
      }
      {searchOption.TYPE != 'pulldown' &&
      <Button onClick={(()=>doSearch())} className={`${classes.searchBtn} search-attach` } variant="contained" color="primary" sx={{ p: '10px' }} aria-label="directions">
        <SearchIcon sx={{ p: '5px' }} />
        Search
      </Button>
      }
    </Paper>
  )
}

  console.log("render client app")
  console.log("props.main.showSetup", props.main.showSetup);
  var banner = props.main.showSetup?.PUBLIC?.HEADER_BANNER;
  var header_color = props.main.showSetup?.PUBLIC?.HEADER_COLOR;    
  if(banner == "undefined") banner = null
  var download_show = true
  var contact_show = true
  if(props.main.showSetup?.PUBLIC?.DOWNLOAD_LINKS == 'hide'){
    download_show = false
  }
  if(props.main.showSetup?.PUBLIC?.CONTACT_INFO == 'hide'){
    contact_show = false
  }
  var sales_bookmarks_show = true
  if(props.main.showSetup?.PUBLIC?.BOOKMARKS == 'hide'){
    sales_bookmarks_show = false
  }
  var public_path = ''
  if(props.urlToken){
    public_path = props.main.authInfo.tokenInfo?.ESHOW_PATH
  }
  let existFeatured = Boolean(filteredItems.filter((item)=>item.FEATURED)?.length)

  let exhibitorsLabel = pageSetup?.EXHIBITOR_LABEL??'Exhibitors';
  let featuredLabel = pageSetup?.FEATURED_LABEL??'Featured';
  return (
    <Box id="xhbt-main-container" className="App Client">
      <ToolBox {...props}/>
      <Box className="xhbt-data-box" style={{maxWidth: 1240, margin:'64px auto', paddingLeft: 10, paddingRight:10, marginTop:(banner && banner != ''?(150+64):64)}}>        
        <Box mb={1}>
        {searchBox()}
        </Box>
        {existFeatured &&
        <>
        {/* <h3 style={{textAlign:'left'}}>Featured</h3> */}
        <Box className="table-list">
          <Table className="" sx={{ minWidth: 650}} aria-label="simple table">
            <TableHead className="header-item table-brand-header" style={{background:header_color?`${convertRgb(header_color)}`:'white'}}>
                <TableRow >
                  <TableCell 
                    sortDirection={orderBy === 'COMPANY_NAME' ? order : false}
                    pl={1} style={{width:(props.main.showSetup?.MAP_LOCATION == 'show'?'45%':'70%'), fontSize:'14px', cursor:'pointer'}}
                    onClick={()=>{
                    }} 
                  >                    
                    <TableSortLabel
                        active={orderBy === 'COMPANY_NAME'}
                        direction={orderBy === 'COMPANY_NAME' ? order : 'asc'}
                        onClick={()=>createSortHandler('COMPANY_NAME')}
                        IconComponent={() =>(
                          <>
                            {orderBy === 'COMPANY_NAME' && order=== 'asc' &&
                              <ArrowUpwardIcon fontSize="small" style={{ color: 'blue' }} /> 
                            }
                            {orderBy === 'COMPANY_NAME' && order=== 'desc' &&
                              <ArrowDownwardIcon fontSize="small" style={{ color: 'blue' }} />
                            }
                          </>
                          )
                        }
                      >
                        Company Name
                        {orderBy === 'COMPANY_NAME' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                  </TableCell>
                  {props.main.showSetup?.MAP_LOCATION == 'show' &&
                  <TableCell 
                    sortDirection={orderBy === 'MAP_NAME' ? order : false} 
                    pl={1} style={{width:'15%', fontSize:14.5, color: "#505050", textAlign:'center', cursor:'pointer'}}
                    onClick={()=>{
                    }} 
                  >
                    <TableSortLabel
                        active={orderBy === 'MAP_NAME'}
                        direction={orderBy === 'MAP_NAME' ? order : 'asc'}
                        onClick={()=>createSortHandler('MAP_NAME')}
                        IconComponent={() =>(
                          <>
                            {orderBy === 'MAP_NAME' && order=== 'asc' &&
                              <ArrowUpwardIcon fontSize="small" style={{ color: 'blue' }} /> 
                            }
                            {orderBy === 'MAP_NAME' && order=== 'desc' &&
                              <ArrowDownwardIcon fontSize="small" style={{ color: 'blue' }} />
                            }
                          </>
                          )
                        }
                    >
                      Location
                      {orderBy === 'MAP_NAME' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                    
                  </TableCell>
                  }
                  <TableCell 
                    sortDirection={orderBy === 'BOOTHS' ? order : false} 
                    pl={1} style={{width:'15%', fontSize:14.5, color: "#505050", textAlign:'center', cursor:'pointer'}}
                    onClick={()=>{
                    }} 
                  >
                    <TableSortLabel
                        active={orderBy === 'BOOTHS'}
                        direction={orderBy === 'BOOTHS' ? order : 'asc'}
                        onClick={()=>createSortHandler('BOOTHS')}
                        IconComponent={() =>(
                          <>
                            {orderBy === 'BOOTHS' && order=== 'asc' &&
                              <ArrowUpwardIcon fontSize="small" style={{ color: 'blue' }} /> 
                            }
                            {orderBy === 'BOOTHS' && order=== 'desc' &&
                              <ArrowDownwardIcon fontSize="small" style={{ color: 'blue' }} />
                            }
                          </>
                          )
                        }
                    >
                      Booth
                      {orderBy === 'BOOTHS' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                    
                  </TableCell>
                  {/* <TableCell pl={1} style={{width:'15%', fontSize:14.5, color: "#505050", textAlign:'center'}}
                    onClick={()=>{
                    }} 
                  >
                    <TableSortLabel
                        active={orderBy === 'FEATURED'}
                        direction={orderBy === 'FEATURED' ? order : 'asc'}
                        onClick={()=>createSortHandler('FEATURED')}
                        IconComponent={() =>(
                          <>
                            {orderBy === 'FEATURED' && order=== 'asc' &&
                              <ArrowUpwardIcon fontSize="small" style={{ color: 'blue' }} /> 
                            }
                            {orderBy === 'FEATURED' && order=== 'desc' &&
                              <ArrowDownwardIcon fontSize="small" style={{ color: 'blue' }} />
                            }
                          </>
                          )
                        }
                    >
                      Featured
                      {orderBy === 'FEATURED' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                    
                  </TableCell> */}
                  <TableCell pl={1} style={{width:'15%', fontSize:14.5, color: "#505050", textAlign:'center'}}
                    onClick={()=>{
                    }} 
                  >
                     <TableSortLabel
                        active={orderBy === 'BOOKMARKED'}
                        direction={orderBy === 'BOOKMARKED' ? order : 'asc'}
                        onClick={()=>createSortHandler('BOOKMARKED')}
                        IconComponent={() =>(
                          <>
                            {orderBy === 'BOOKMARKED' && order=== 'asc' &&
                              <ArrowUpwardIcon fontSize="small" style={{ color: 'blue' }} /> 
                            }
                            {orderBy === 'BOOKMARKED' && order=== 'desc' &&
                              <ArrowDownwardIcon fontSize="small" style={{ color: 'blue' }} />
                            }
                          </>
                          )
                        }
                    >
                      Bookmark
                      {orderBy === 'BOOKMARKED' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell colSpan={(props.main.showSetup?.MAP_LOCATION == 'show'?'4':'3')} style={{width:(props.main.showSetup?.MAP_LOCATION == 'show'?'45%':'70%'), fontSize:'15.5px', background:'#e0e0e0'}}
                  >
                    <div style={{paddingTop:4, paddingBottom:4}}><strong>{featuredLabel}</strong></div>
                  </TableCell>
                
              </TableRow>
              {stableSort(filteredItems.filter((item)=>item.FEATURED), getComparator(order, orderBy)).map((exhibitor, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell style={{width:(props.main.showSetup?.MAP_LOCATION == 'show'?'45%':'70%'), fontSize:'14px', cursor:'pointer'}}
                    onClick={()=>{
                      navigate(`${composeUrl(public_path, 'exhibitor/profile/'+(exhibitor.BOOTHS?exhibitor.BOOTHS:""))}`)
                    }} 
                  >
                    {(exhibitor.COMPANY_NAME?(` ${exhibitor.COMPANY_NAME}`):"")}
                  </TableCell>
                  {props.main.showSetup?.MAP_LOCATION == 'show' &&
                  <TableCell style={{width:'15%', fontSize:14.5, color: "#505050", textAlign:'center', cursor:'pointer'}}
                    onClick={()=>{
                      navigate(`${composeUrl(public_path, 'exhibitor/profile/'+(exhibitor.BOOTHS?exhibitor.BOOTHS:""))}`)
                    }} 
                  >
                    {(exhibitor.MAP_NAME?(` ${exhibitor.MAP_NAME}`):"")}
                  </TableCell>
                  }
                  <TableCell pl={1} style={{width:'15%', fontSize:14.5, color: "#505050", textAlign:'center', cursor:'pointer'}}
                    onClick={()=>{
                      navigate(`${composeUrl(public_path, 'exhibitor/profile/'+(exhibitor.BOOTHS?exhibitor.BOOTHS:""))}`)
                    }} 
                  >
                    <b>{(exhibitor.BOOTHS?exhibitor.BOOTHS:"")}</b>
                  </TableCell>
                  {/* <TableCell pl={1} style={{width:'15%', fontSize:14.5, color: "#505050", textAlign:'center'}}
                  >
                    {
                      exhibitor.FEATURED?
                      <CheckIcon style={{color:'green'}}/>:
                      <></>
                    }
                  </TableCell> */}
                  <TableCell pl={1} style={{width:'15%', fontSize:14.5, color: "#505050", textAlign:'center'}}
                  >
                    {sales_bookmarks_show && 
                      <>
                        {
                          exhibitor.BOOKMARKED?
                          <BookmarkSharpIcon onClick={()=>{ doBookmark(exhibitor)}} style={{color:'#e54839'}}/>:
                          <BookmarkBorderSharpIcon onClick={()=>{ doBookmark(exhibitor)}}/>
                        }
                      </>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>         
        </Box>
        </>
        }        
        {/* {filteredItems.filter((item)=>item.FEATURED)?.length &&
        <h3 style={{textAlign:'left'}}>Exhibitors</h3>
        } */}
        <Box className="table-list">
          <Table className="" sx={{ minWidth: 650}} aria-label="simple table">
            {!existFeatured &&
            <TableHead className="header-item">
                <TableRow >
                  <TableCell 
                    sortDirection={orderBy === 'COMPANY_NAME' ? order : false}
                    pl={1} style={{width:(props.main.showSetup?.MAP_LOCATION == 'show'?'45%':'70%'), fontSize:'14px', cursor:'pointer'}}
                    onClick={()=>{
                    }} 
                  >                    
                    <TableSortLabel
                        active={orderBy === 'COMPANY_NAME'}
                        direction={orderBy === 'COMPANY_NAME' ? order : 'asc'}
                        onClick={()=>createSortHandler('COMPANY_NAME')}
                        IconComponent={() =>(
                          <>
                            {orderBy === 'COMPANY_NAME' && order=== 'asc' &&
                              <ArrowUpwardIcon fontSize="small" style={{ color: 'blue' }} /> 
                            }
                            {orderBy === 'COMPANY_NAME' && order=== 'desc' &&
                              <ArrowDownwardIcon fontSize="small" style={{ color: 'blue' }} />
                            }
                          </>
                          )
                        }
                      >
                        Company Name
                        {orderBy === 'COMPANY_NAME' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                  </TableCell>
                  {props.main.showSetup?.MAP_LOCATION == 'show' &&
                  <TableCell 
                    sortDirection={orderBy === 'MAP_NAME' ? order : false} 
                    pl={1} style={{width:'15%', fontSize:14.5, color: "#505050", textAlign:'center', cursor:'pointer'}}
                    onClick={()=>{
                    }} 
                  >
                    <TableSortLabel
                        active={orderBy === 'MAP_NAME'}
                        direction={orderBy === 'MAP_NAME' ? order : 'asc'}
                        onClick={()=>createSortHandler('MAP_NAME')}
                        IconComponent={() =>(
                          <>
                            {orderBy === 'MAP_NAME' && order=== 'asc' &&
                              <ArrowUpwardIcon fontSize="small" style={{ color: 'blue' }} /> 
                            }
                            {orderBy === 'MAP_NAME' && order=== 'desc' &&
                              <ArrowDownwardIcon fontSize="small" style={{ color: 'blue' }} />
                            }
                          </>
                          )
                        }
                    >
                      Location
                      {orderBy === 'MAP_NAME' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                    
                  </TableCell>
                  }
                  <TableCell 
                    sortDirection={orderBy === 'BOOTHS' ? order : false} 
                    pl={1} style={{width:'15%', fontSize:14.5, color: "#505050", textAlign:'center', cursor:'pointer'}}
                    onClick={()=>{
                    }} 
                  >
                    <TableSortLabel
                        active={orderBy === 'BOOTHS'}
                        direction={orderBy === 'BOOTHS' ? order : 'asc'}
                        onClick={()=>createSortHandler('BOOTHS')}
                        IconComponent={() =>(
                          <>
                            {orderBy === 'BOOTHS' && order=== 'asc' &&
                              <ArrowUpwardIcon fontSize="small" style={{ color: 'blue' }} /> 
                            }
                            {orderBy === 'BOOTHS' && order=== 'desc' &&
                              <ArrowDownwardIcon fontSize="small" style={{ color: 'blue' }} />
                            }
                          </>
                          )
                        }
                    >
                      Booth
                      {orderBy === 'BOOTHS' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                    
                  </TableCell>
                  {/* <TableCell pl={1} style={{width:'15%', fontSize:14.5, color: "#505050", textAlign:'center'}}
                    onClick={()=>{
                    }} 
                  >
                    <TableSortLabel
                        active={orderBy === 'FEATURED'}
                        direction={orderBy === 'FEATURED' ? order : 'asc'}
                        onClick={()=>createSortHandler('FEATURED')}
                        IconComponent={() =>(
                          <>
                            {orderBy === 'FEATURED' && order=== 'asc' &&
                              <ArrowUpwardIcon fontSize="small" style={{ color: 'blue' }} /> 
                            }
                            {orderBy === 'FEATURED' && order=== 'desc' &&
                              <ArrowDownwardIcon fontSize="small" style={{ color: 'blue' }} />
                            }
                          </>
                          )
                        }
                    >
                      Featured
                      {orderBy === 'FEATURED' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                    
                  </TableCell> */}
                  <TableCell pl={1} style={{width:'15%', fontSize:14.5, color: "#505050", textAlign:'center'}}
                    onClick={()=>{
                    }} 
                  >
                     <TableSortLabel
                        active={orderBy === 'BOOKMARKED'}
                        direction={orderBy === 'BOOKMARKED' ? order : 'asc'}
                        onClick={()=>createSortHandler('BOOKMARKED')}
                        IconComponent={() =>(
                          <>
                            {orderBy === 'BOOKMARKED' && order=== 'asc' &&
                              <ArrowUpwardIcon fontSize="small" style={{ color: 'blue' }} /> 
                            }
                            {orderBy === 'BOOKMARKED' && order=== 'desc' &&
                              <ArrowDownwardIcon fontSize="small" style={{ color: 'blue' }} />
                            }
                          </>
                          )
                        }
                    >
                      Bookmark
                      {orderBy === 'BOOKMARKED' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
            </TableHead>
            }
            <TableBody>
             {existFeatured &&
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell colSpan={(props.main.showSetup?.MAP_LOCATION == 'show'?'4':'3')} style={{width:(props.main.showSetup?.MAP_LOCATION == 'show'?'45%':'70%'), fontSize:'15.5px', borderTop:'1px solid rgba(224, 224, 224, 1)', background:'#e0e0e0'}}
                  >
                    <div style={{paddingTop:4, paddingBottom:4}}><strong>{exhibitorsLabel}</strong></div>
                  </TableCell>
                
              </TableRow>
              }
              {stableSort(filteredItems.filter((item)=>!item.FEATURED), getComparator(order, orderBy)).map((exhibitor, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell style={{width:(props.main.showSetup?.MAP_LOCATION == 'show'?'45%':'70%'), fontSize:'14px', cursor:'pointer'}}
                    onClick={()=>{
                      navigate(`${composeUrl(public_path, 'exhibitor/profile/'+(exhibitor.BOOTHS?exhibitor.BOOTHS:""))}`)
                    }} 
                  >
                    {(exhibitor.COMPANY_NAME?(` ${exhibitor.COMPANY_NAME}`):"")}
                  </TableCell>
                  {props.main.showSetup?.MAP_LOCATION == 'show' &&
                  <TableCell style={{width:'15%', fontSize:14.5, color: "#505050", textAlign:'center', cursor:'pointer'}}
                    onClick={()=>{
                      navigate(`${composeUrl(public_path, 'exhibitor/profile/'+(exhibitor.BOOTHS?exhibitor.BOOTHS:""))}`)
                    }} 
                  >
                    {(exhibitor.MAP_NAME?(` ${exhibitor.MAP_NAME}`):"")}
                  </TableCell>
                  }
                  <TableCell pl={1} style={{width:'15%', fontSize:14.5, color: "#505050", textAlign:'center', cursor:'pointer'}}
                    onClick={()=>{
                      navigate(`${composeUrl(public_path, 'exhibitor/profile/'+(exhibitor.BOOTHS?exhibitor.BOOTHS:""))}`)
                    }} 
                  >
                    <b>{(exhibitor.BOOTHS?exhibitor.BOOTHS:"")}</b>
                  </TableCell>
                  {/* <TableCell pl={1} style={{width:'15%', fontSize:14.5, color: "#505050", textAlign:'center'}}
                  >
                    {
                      exhibitor.FEATURED?
                      <CheckIcon style={{color:'green'}}/>:
                      <></>
                    }
                  </TableCell> */}
                  <TableCell pl={1} style={{width:'15%', fontSize:14.5, color: "#505050", textAlign:'center'}}
                  >
                    {sales_bookmarks_show && 
                      <>
                        {
                          exhibitor.BOOKMARKED?
                          <BookmarkSharpIcon onClick={()=>{ doBookmark(exhibitor)}} style={{color:'#e54839'}}/>:
                          <BookmarkBorderSharpIcon onClick={()=>{ doBookmark(exhibitor)}}/>
                        }
                      </>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>         
        </Box>
      </Box>
      {!loaded &&
      <LoadingOverlay
            active={!loaded}
            fadeSpeed = {100}
            styles={{
                overlay: (base) => ({
                ...base,
                fontSize:'18px',
                color: 'rgb(5, 37, 51)',
                // background: 'rgb(229 229 229 / 92%)',
                background: 'transparent',
                position:'fixed',
                top: '49px',
                zIndex:1000000000
                })
            }}
            spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
            // spinner
            // text='Loading ...'
            >
        </LoadingOverlay> 
      }
      
      <LoginModal/>
    </Box>
  );
}


const mapStateToProps = (state) => {
  return {
    main: state.main,
    booth_history: state.booth_history.present,
    ui: state.ui,
    cart: state.cart,
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
    setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
    setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
    setLeftBoothList:  data=>dispatch(setLeftBoothList(data)),
    setBookmardUpdated:   data=>dispatch(setBookmardUpdated(data)),
    setBookmarkList:  data=>dispatch(setBookmarkList(data)),
    setLeftSelectedCategory:  data=>dispatch(setLeftSelectedCategory(data)),
    setLeftPage: value=>dispatch(setLeftPage(value)) ,
    setOpenCompanyModal:  data=>dispatch(setOpenCompanyModal(data)),
    setEditCompany: value=>dispatch(setEditCompany(value)),
    setSnackMsg: data=>dispatch(setSnackMsg(data)),
    setOpenSnack: data=>dispatch(setOpenSnack(data)),
    setCustomPageExhibitorlist: data=>dispatch(setCustomPageExhibitorlist(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
