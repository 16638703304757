import logo from './logo.svg';
import React ,{ useEffect, useRef, useState } from 'react';
import './App.css';
import { connect } from 'react-redux'
import MainViewerAdmin from './viewer/MainViewerAdmin';
import { setBoothLoaded, setMainCanvas,setBgImgCanvas, setMainSvg, setImportLayers, setMainLayers, setBoothList, setBoothFileList, setCanvasRefresh, setLoading, setActiveBoothFile, setMenuControl, setShowGrid, resetMain, setPricingOptions, setPremiumOptions, setDiscountOptions, setGroupOptions, setCategoryOptions, setAuthInfo, setFirstMapContent, setFirstBgContent, setShowSetup } from './actions/main'
import { setOpenBoothList, setOpenLogin, setMenuList, setCustomStyles } from './actions/ui'
import { parseToken, buildObjectProperty, download, containsObject, convertRgbHex, convertRgb, getDefaultGroup, uuidRegexExp, isUUID, setCookie, getCookie, getTokenCookie, getTokenPlanner} from './utils/Common'
import ExhibitorSearch from './ExhibitorSearch';
import ClientApp from './ClientApp'
import HeatApp from './HeatApp'
import ExhibitorFeatured from './ExhibitorFeatured';
import ProductFeatured from './ProductFeatured';
import ProductShowCase from './ProductShowCase';
import ShowSpecials from './ShowSpecials';
import Contactlist from './Contactlist'
import PressReleases from './PressReleases'
import ExhibitorProfile from './ExhibitorProfile'
import CustomPage from './CustomPage'
import MyPlanner from './MyPlanner';
import QuestionPage from './QuestionPage';
import CartPage from './CartPage';
import ExhibitorList from './ExhibitorList';
import ExhibitorBoothProfile from './ExhibitorBoothProfile';
import qs, { parse } from 'qs'
import axios from 'axios';
import { useParams } from 'react-router-dom'
import { API_URL, ESHOW_URL } from './config.js'

import {
  BrowserRouter,
  Routes,
  Route,  
  useLocation
} from "react-router-dom";

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
function Index(props) {
  const { id1, id2, id3, id4, id5 } = useParams();
  let query = useQuery();

  useEffect(async () => {      
    if(props.urlToken){
      var public_token = `/${id1}`
      if(id2){
        public_token = `${public_token}/${id2}`
      }
      if(id3){
        public_token = `${public_token}/${id3}`
      }
      if(id4){
        public_token = `${public_token}/${id4}`
      }
      if(id5){
        public_token = `${public_token}/${id5}`
      }
      setCookie('V3PUBLICMAP', public_token)
    }       
    var SALES = await processAuth()  
    // fetchShowSetup()
    fetchMenu(SALES)
  }, [query, id5, id4, id3, id2, id1])

  var authInfo = props.main.authInfo
  const processAuth = async ()=>{  
    fetchShowSetup()    
    fetchCustomStyles();  
    var tokenInfo = await parseToken(query)
    var show_key = tokenInfo.show_key?tokenInfo.show_key:'';
    var user_key = tokenInfo.user_key?tokenInfo.user_key:'';
    var user_name = tokenInfo.user_key?tokenInfo.user_name:'';
    var BOOTH_PRICING = tokenInfo.BOOTH_PRICING?tokenInfo.BOOTH_PRICING:'';
    var SHOPPING_CART = tokenInfo.SHOPPING_CART?tokenInfo.SHOPPING_CART:'';
    var SALES = tokenInfo.SALES
    props.setAuthInfo({show_key, user_key, user_name, SALES ,BOOTH_PRICING,SHOPPING_CART, authenticated: (tokenInfo.show_key && tokenInfo.user_key?3:2), tokenInfo})
    if(!(tokenInfo.show_key && tokenInfo.user_key)){ 
        props.setLoading(false)  
        return SALES
    }
    return SALES
  }

  const fetchCustomStyles = async ()=>{
    if(query.get('sales_map')) {                        
      props.setCustomStyles({})
      return;
    }
    const options = {
      headers: {
        'Authorization': `Bearer ${getTokenCookie()}`
      }
    } 
    const response = await axios.get(`${ESHOW_URL()}?method=getCSS`, options)
    if(response.status == 200){
        var data = response.data
        if(data.SUCCESS == true){
            if(data.CSS){              
                props.setCustomStyles(data.CSS)
            }
        }
    }
    else{
        console.log("")
    }
  }

  const fetchMenu=(SALES)=>{
    var sales_header_show = true
    if(SALES){
      if(SALES.MAP_TYPE == 'sales'){
        sales_header_show = false
      }
    }
    if(sales_header_show){
      const options = {
        headers: {
          'Authorization': `Bearer ${getTokenCookie()}`
        }
      } 
      axios.get(`${ESHOW_URL()}?method=getTopMenu`, options).then(res=>{
          const result = res.data;
          if(result.SUCCESS){  
              if(result.MENU){  
                var menues = result.MENU
                menues.sort(function (a, b) {
                  return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
                });              
                props.setMenuList(menues)
              }
          }
          else{
          }
      }).catch(error => {
      })  
    }
  }

  const fetchShowSetup =async ()=>{
    const options = {
      headers: {
        'Authorization': `Bearer ${getTokenCookie()}`
      }
    }  
    const response = await axios.get(`${ESHOW_URL()}?method=getShowSetup`, options)
    var result = false;
    if(response.status == 200){
        var data = response.data
        if(data.SUCCESS == true){
            result = data
            props.setShowSetup(result)
        }
    }
    else{
        console.log("")
    }
    return result;
  }

  const getComponent=()=>{
    if(props.path == "heatmap"){
      return <HeatApp page ="2d" urlToken={true}/>
    }  
    else if(props.ui.menuList && props.ui.menuList.length){
      let profileFlag = false;
      if(isUUID(id4)){
        return <ExhibitorBoothProfile page ="2d" urlToken={true} exhibitorKey={id4} />
      }
      if(isUUID(id5)){
        return <ExhibitorBoothProfile page ="2d" urlToken={true} exhibitorKey={id5} />
      }
      let path = `${id1}/${id2}/${id3}/${id4}/${id5}`
      if(path.includes('exhibitor/profile')){
        return <ExhibitorBoothProfile page ="2d" urlToken={true}/>
      }
      if(path.includes('exhibitor/list')){
        return <ExhibitorList page ="2d" urlToken={true}/>
      }
      if(id3=='filter' || id4=='filter'){
        return <QuestionPage page ="2d" urlToken={true}/>
      }
      if(id3=='invite' || id4=='invite'){
        return <CartPage page ="2d" urlToken={true}/>
      }
      for(var i = 0; i < props.ui.menuList?.length; i++){
        var menu = props.ui.menuList[i];
        if(menu.PAGE_NAME == id5 || menu.PAGE_NAME == id4 || menu.PAGE_NAME == id3){
          if(menu.URL == 'V3_MAPS'){
            return <ClientApp page ="2d" urlToken={true}/>
          }
          if(menu.URL == 'V3_SEARCH'){
            return <ExhibitorSearch page ="2d" urlToken={true}/>
          }
          if(menu.URL == 'V3_FEATURED'){
            return <ExhibitorFeatured page ="2d" urlToken={true}/>
          }
          if(menu.URL == 'V3_PRODUCTS'){
            return <ProductFeatured page ="2d" urlToken={true}/>
          }
          if(menu.URL == 'product_showcase'){
            return <ProductShowCase page ="2d" urlToken={true}/>
          }          
          if(menu.URL == 'V3_SPECIALS'){
            return <ShowSpecials page ="2d" urlToken={true}/>
          }          
          if(menu.URL == 'V3_CONTACTS'){
            return <Contactlist page ="2d" urlToken={true}/>
          }          
          if(menu.URL == 'V3_PRESS'){
            return <PressReleases page ="2d" urlToken={true}/>
          }          
          if(menu.URL == 'V3_MYPLANNER'){
            return <MyPlanner page ="2d" urlToken={true}/>
          }        
          if((menu.URL == 'V3_CUSTOM1' || menu.URL == 'V3_CUSTOM2') && !menu.EXTERNAL_URL){
            return <CustomPage page ="2d" urlToken={true}/>
          }
        }
      }
      return <ClientApp page ="2d" urlToken={true}/>
    }
    return <></>
  }

  return (
    <>
    {authInfo?.authenticated == 3 && authInfo?.tokenInfo?.SERVER_NAME &&
    <>
      <link rel="stylesheet" href={`https://${authInfo?.tokenInfo?.SERVER_NAME}${authInfo?.tokenInfo?.ESHOW_PATH}/css/siteVariables.css`}/>
      <link rel="stylesheet" href={`https://${authInfo?.tokenInfo?.SERVER_NAME}/assets/css/exhibit/core/core-template.css`}/>
      <script src={`https://${authInfo?.tokenInfo?.SERVER_NAME}/assets/js/exhibit/core/core-template.js`}></script>
    </>
    }
    <div>
      {/* {authInfo?.authenticated == 3 && */}
      <>
      {getComponent()}
      </>
      {/* } */}
    </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    main: state.main,
    booth_history: state.booth_history.present,
    ui: state.ui,
    cart: state.cart,
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
      setMenuList: value=>dispatch(setMenuList(value)) ,
      setCustomStyles: data=>dispatch(setCustomStyles(data)),
      setLoading:  data=>dispatch(setLoading(data)),
      setAuthInfo:  data=>dispatch(setAuthInfo(data)),
      setShowSetup:  data=>dispatch(setShowSetup(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
